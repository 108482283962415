
.footer-text{
    margin-top: 0px;
    color: #fff
}
.footer{
    background-color: #13002e;
}

.footer2{
    background-color: #13002e;
    height: 50px;
}
.footer-headline{
    color: #ffffff;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.touch-with-us-text{
    color: #fff;
    text-decoration: none;
}
.touch-with-us-text:hover{
    color: #ec008c;
}

.quick-link-text{
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    display: block;
}
.quick-link-text:hover{
    background-color: #ec008c;
    padding: 5px;
    color: #ffffff;
    transition: 0.5s;
    border-radius: 20px 50px;
}



.effect {
    width: 100%;
}

.effect .buttons {
    display: flex;
}
.effect a:last-child {
    margin-right: 0px;
}

.effect a {
    text-decoration: none !important;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-right: 20px;
    font-size: 20px;
    overflow: hidden;
    position: relative;
}
.effect a i {
    position: relative;
    z-index: 3;
}
.effect a.fb {
    background-color: #4267B2;
}
.effect a.youtube {
    background-color: #ff0000;
}
.effect a.linkedin {
    background-color: #0072b1;
}

/* aeneas effect */
.effect.aeneas a {
    transition: transform 0.4s linear 0s, border-top-left-radius 0.1s linear 0s, border-top-right-radius 0.1s linear 0.1s, border-bottom-right-radius 0.1s linear 0.2s, border-bottom-left-radius 0.1s linear 0.3s;
}
.effect.aeneas a i {
    transition: transform 0.4s linear 0s;
}
.effect.aeneas a:hover {
    transform: rotate(360deg);
    border-radius: 50%;
}
.effect.aeneas a:hover i {
    transform: rotate(-360deg);
}

.counter-text{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; 
    font-weight: 600;
}
.visitor-amount{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 600;
    color: #ec008c;
}