

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .mainbody{
  padding-top: 65px;
} */
/* @media screen and (max-width: 991px){
  .mainbody{
    padding-top: 80px;
  }
} */