.contact-bg {
  background-image: linear-gradient(#3d3d3d4b, #3d3d3d63),
    url("./../../../public/contact/contactus.jpg");
  width: auto;
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* opacity: 0.3; */
  background-attachment: fixed;
}
@media screen and (max-width: 420px){
  .contact-bg {
    height: 400px;
  }
}
.item--title {
  color: #fff;
}
.contact-bg div {
  padding-top: 10%;
}
.shape-image {
  transform: translate(0px,-90%);
  /* top: 38%; */
  position: absolute;
  width: 100%;
  height: 500px;
  /* transform: rotate(180deg); */
}
.texture {
  background-image: linear-gradient(#fffffff8, #ffffffec),
  url("./../../../public/texture/Asset-2.png");
  height: 100%;
}

.contact-map-headline {
  padding-top: 60px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
}
.cardbox {
  border-radius: 10px;
  background: #ffffff;
  box-shadow:  15px 15px 30px #bfbfbf,
               -15px -15px 30px #ffffff;
}
.contact-us-headline {
  padding-top: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
}

.contactinfo{
  background-image: linear-gradient(#111111f1, #111111a8),
    url("./../../../public/contact/B-2.jpg");
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}
.get-in-touch{
  padding-top: 60px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.address-icon-box{
  font-size: 40px;
  margin: 20px auto;
  page-break-after: 20px;
  padding: 10px;
  height: 80px;
  width: 80px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
}
.address-icon-box:hover{
  background-color: #0400ff;
  border: 1px solid #0400ff;
  transition: 0.5s;

}

.address-headline{
  color: #00d9ff;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}
.glassmorphism{
  height: 320px;
  background: #f400ff40;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 1.5px );
  -webkit-backdrop-filter: blur( 1.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

/* @media screen and (min-height: 400px) {
  .shape-image {
    top: 60%;
  }
} */