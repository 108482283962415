.carsour-image{
    height: 650px;
}
.carousel-indicators {
    bottom: 10% !important;
    left: 30% !important;
    z-index: 2 !important;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    width: 15px !important;
    height: 15px !important;
    padding: 0 !important;
    margin-right: 1px !important;
    margin-left: 1px !important;
    background-color: #ec008c !important;
    border-radius: 50% !important;
    opacity: .3 !important;
    transition: opacity .6s ease !important;
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none !important;
    }
}

.carousel-indicators .active {
    opacity: 1 !important;
}

.carousel-caption{
    bottom: 50% !important;
    right: 10% !important;
    z-index: 2 !important;
    text-align: right !important;
    /* width: 100%; */
    
}
.carousel-caption h2{
    right: 0% !important;
    width: 550px;
    float: right;
    text-transform: uppercase;
    font-size: 40px;
     font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.texture-1{
    background-image: linear-gradient(#fffffff8, #ffffffec),
    url("./../../../public/texture/Asset-2.png");
    background-size: cover;
}

.history-img{
    width: 400px;
    height: 346.4px; /* width * 0.866 */; 
    filter: drop-shadow(0px 5px 10px #ec008c);
}

.history-image-shape{
  width: 100%; /* container height - (border thickness * 2) */
  height: 100%; /* container height - (border thickness * 2) */
  clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}
.history-side-image {
    width: 100%; 
    height: 100%; 
    clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    /* -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%); */
    /* box-shadow: 0px 5px 10px #ec008c; */
  }

  @media screen and (max-width: 420px){
    .history-img{
        width: 300px;
        height: 259.8; /* width * 0.866 */
    }
    .carousel-caption h2{
        width: 300px;
        font-size: 24px;
    }
    .carsour-image{
        height: 400px;
    }
    .carousel-indicators [data-bs-target] {
        width: 5px !important;
        height: 5px !important;
        padding: 0 !important;
    }
}

.katalyst-history-headline {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    color: #0083ca;
}
.history-short-description{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    justify-content: center;
}




@media screen and (max-width: 760px){
    .history-img {
        padding-top: 10%;
    }
    .katalyst-history-headline {
        padding-top: 0px;
    }
}


  .company-history-point div i{
      color: #ec008c;
  }
  .company-history-point div p{
    padding-top: 10px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #0083ca;
  }
  .custom-shape{
      position: relative;
  }
  .shape-image1 {
    transform: translate(0px,-10%);
    width: 100%;
    height: 150px;
  }

  .homepage-product-left-block{
      /* position: sticky; */
      overflow: visible;
      top: 140px;
  }

.tranging-product-section{
    background-image: linear-gradient(#0000004f, #00000044),
    url("./../../../public/homepage/homepage-3.jpg");
  width: auto;
  /* height: 650px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.tranding-product-title{
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
}