.career-bg {
    background-image: linear-gradient(#3d3d3da1, #3d3d3d63),
      url("./../../../public/job/career.png");
    width: auto;
    height: 650px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* opacity: 0.3; */
    background-attachment: fixed;
  }
  @media screen and (max-width: 420px){
    .career-bg {
      height: 400px;
    }
  }
  .career-bg div {
    padding-top: 15%;
  }

  .job_blocks{
    text-decoration: none;
  }
  .position_name{
    font-size:25px;
    font-weight: 600;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: #552e8c;
  }
  .application_dead_line{
    color: #000;
  }

  .blinking{
    font-size: 20px;
    font-weight: bold;
    animation:blinkingText 1.2s infinite;
  }
  @keyframes blinkingText{
    0%{    color: #39f500;    }
    49%{    color: #39f500;    }
    50%{    color: transparent; }
    99%{    color: transparent; }
    100%{   color: #39f500;    }
  }
  