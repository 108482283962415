.product-bg {
  background-image: linear-gradient(#3d3d3da1, #3d3d3d63),
    url("./../../../public/product/product-background.jpg");
  width: auto;
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* opacity: 0.3; */
  background-attachment: fixed;
}

.product-bg div {
    padding-top: 10%;
  }
.basic-chemicals-title {
  /* padding-top: 60px; */
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  color: #0083ca;
  text-align: center;
}

.basic-chemicals-title:after,
.basic-chemicals-title:before {
  position: relative;
  content: " ";
  display: inline-block;
  width: 100px;
  height: 3px;
  background: #ff0000;
  vertical-align: middle;
}
.basic-chemicals-subtitle {
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: gray;
  font-size: 18px;
}


/* Product Details Design */
.product-details-octa-img-block {
  padding-bottom: 20px;
  width: 400px;
  height: calc(400px * 0.866) /* width * 0.866 */; 
  filter: drop-shadow(0px 5px 10px #ec008c);
}
.product-details-octa-img-block2 {
  width: 100%;
  /* container height - (border thickness * 2) */
  height: 100%; 
  /* container height - (border thickness * 2) */
  clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}
.product-details-octa-image {
  width: 100%; 
  height: 130%; 
  /* clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%); */
}
@media screen and (max-width: 420px){
  .product-details-octa-img-block{
      width: 300px;
      height: 259.8; /* width * 0.866 */
  }
}

.product-description-title{
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 600;
  color: #0083ca;
}
.product-description{
  font-size: 18px;
  font-weight: 500;
}

.item-hover:hover{
  text-decoration: underline;
  cursor: pointer;
  color: #ec008c;
  font-size: 20px;
  transition: 0.5s;
  padding-left: 10px;
}

@media screen and (max-width: 420px){
  .product-bg {
    height: 400px;
  }
  .basic-chemicals-title {
    /* padding-top: 60px; */
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 20px;
  }
  .basic-chemicals-title:after,
.basic-chemicals-title:before {
  position: relative;
  content: " ";
  display: inline-block;
  width: 50px;
  height: 3px;
  background: #ff0000;
  vertical-align: middle;
}
}