.navbar{
  z-index: 99;
  
  width: 100%;
  /* margin-bottom: 50px; */
}
#nav-bar{
  position: sticky;
  top: 0;
  z-index: 10;
  
}

.ftco-navbar-light {
  background: #ffffff !important;
  z-index: 3;
  padding: 0;
  position: fixed;
}
@media (max-width: 991.98px) {
  .ftco-navbar-light {
    background: #ffffff !important;
    top: 0;
    padding: 10px 15px;
  }
}
@media (max-width: 991.98px) {
  .ftco-navbar-light .navbar-nav {
    padding-bottom: 10px;
  }
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
  font-size: 14px;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  opacity: 1 !important;
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #ec008c;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
  color: #ec008c;
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover:before {
  width: 100%;
}
@media (max-width: 991.98px) {
  .ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    color: #000000;
  }
  .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
    color: #552e8c;
  }
}
/* @media (max-width: 767.98px) {
  .ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
} */
.dropdown_auto .dropdown-menu {
  border: none;
  background: #fff;
  -webkit-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
  box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
  border-radius: 4px;
}
.dropdown_auto_menu .dropdown-item {
  font-size: 14px;
  color: #000 !important;
}
.dropdown_auto:hover .dropdown_auto_menu{
  display: block;
}
.dropdown_auto_menu .dropdown-item:hover:after{
  transform: scaleX(1);
  transform-origin: bottom left;
}
.dropdown_auto_menu  .dropdown-item:hover{
  background-color: #F90716 !important;
  color: #fff !important;
  transition: 0.3s;
}
@media screen and (max-width: 991px){
  .dropdown_auto:hover .dropdown_auto_menu{
    display: contents;
  }
  .dropdown_auto_menu .dropdown-item {
    font-size: 16px;
    color: #000000 !important;
  }
}


