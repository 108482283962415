.aboutus-bg {
  background-image: linear-gradient(#3d3d3d4b, #3d3d3d63),
    url("./../../../public/aboutus/aboutus-background-1.png");
  width: auto;
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* opacity: 0.3; */
  background-attachment: fixed;
}
.item--title {
  color: #fff;
}
.aboutus-bg div {
  padding-top: 10%;
}
.shape-image {
  transform: translate(0px, -90%);
  /* top: 38%; */
  position: absolute;
  width: 100%;
  height: 500px;
  /* transform: rotate(180deg); */
}
.texture-3 {
  background-image: linear-gradient(#fffffff8, #ffffffec),
    url("./../../../public/texture/Asset-2.png");
  height: 100%;
}
.background-title {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 600;
  color: #0083ca;
}
.background-title:after,
.background-title:before {
  position: relative;
  content: " ";
  display: inline-block;
  width: 50px;
  height: 4px;
  background: #ff0000;
  vertical-align: middle;
}
.background-description {
  font-size: 18px;
  justify-content: center;
}
.background-image{
    margin-top: 60px;
}
.aboutus-mission-vision{
    background-image: linear-gradient(#0000009d,#0000009d, #0000009d),
    url("./../../../public/aboutus/aboutus-3.jpg");
  width: auto;
  /* height: 650px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 100px;
}
.border-box-1{
    height: 380px;
    background-color: transparent;
    /* box-shadow: 0px 5px 10px #ec008c; */
}
.border-box-2{
    height: 380px;
    background-color: #ec008c;
    justify-content: center;
}
.border-box-3{
    height: 380px;
    background-color: transparent;
    /* box-shadow: 0px 5px 10px #ec008c; */
}
.border-box-4{
    height: 380px;
    background-color: #0083ca;
    padding-top: 5%;
}
.border-box-5{
    height: 380px;
    background-color: transparent;
    padding-top: 10%;
    color: #fff;
}
.border-box-6{
    height: 380px;
    background-color: #552e8c;
    color: #fff;
}

.mission-title{
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
}
.border-box-2 ul{
    color: #fff;
}
.border-box-6 ul{
    color: #fff;
}
@media screen and (max-width: 420px){
  .aboutus-bg {
    height: 400px;
  }
  .aboutus-bg .topheadline{
    font-size: 20px;
  }
}
@media screen and (max-width: 991px){
    .border-box-1{
        display: none;
    }
    .border-box-3{
        display: none; 
    }
    .border-box-5{
        display: none;
    }
}
.custom-shape-box{
    position: relative;
    

}
.custom-shape-image-2{
    width: 100%;
    height: 150px;
    transform: translate(0px,-50%);
}
.why-choose-us-title {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 600;
    color: #0083ca;
  }
  .why-choose-us-title:after,
  .why-choose-us-title:before {
    position: relative;
    content: " ";
    display: inline-block;
    width: 50px;
    height: 4px;
    background: #ff0000;
    vertical-align: middle;
  }
  .why-choose-us-subtitle{
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
  .why-choose-us-description{
    font-size: 18px;
    color: #000000;
  }
  .why-choose-us-progress-bar-title{
        font-size: 20px;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
        font-weight: 600;
        text-transform: uppercase;
  }

  .vmv{
    margin-top: 70px;
    z-index: 10;
  }
  .vision-group{
    color: #ffffff;
    z-index: 0;
  }

  .vision-block{
    left: calc(50% + 150px);
    top: calc(-30% + -450px);
  }
  .vision-block h3:before{
    position: absolute;
    content: " ";
    display: block;
    width: 100px;
    height: 1px;
    background: #fcfcfc;
    /* left: calc(50% + 175px); */
    right: 96%;
    top: calc(58px * 1.2 + 15px);
    transform: rotate(-45deg);
  }
  .vision-block-1{
    margin-top: 15px;
  }
  .vision-block-1 ul{
    border-top: 1px solid #fff;
    max-width: 375px;
    padding-top: 15px;
  }


  .mission-block{
    left: calc(0% + 30px);
    top: calc(30% + -300px);
    /* display: block; */
    width: 375px;
  }
  .mission-block h3{
    text-align: end;
  }
  .mission-block h3::after{
    position: absolute;
    content: " ";
    display: block;
    width: 100px;
    height: 1px;
    background: #fcfcfc;
    /* left: calc(50% + 175px); */
    right: -23%;
    top: calc(58px * 1.2 + 15px);
    transform: rotate(45deg);
  }
  .mission-block-1{
    margin-top: 15px;
  }
  .mission-block-1 ul{
    border-top: 1px solid #fff;
    max-width: 375px;
    padding-top: 15px;
  }

  .mobile-view{
    display: none;
  }

  .value-block{
    left: calc(50% + 250px);
    top: calc(-30% + -250px);
  }
  .value-block h3:before{
    position: absolute;
    content: " ";
    display: block;
    width: 100px;
    height: 1px;
    background: #fcfcfc;
    /* left: calc(50% + 175px); */
    right: 96%;
    top: calc(58px * 1.2 + 15px);
    transform: rotate(-45deg);
  }
  .value-block-1{
    margin-top: 15px;
  }
  .value-block-1 ul{
    border-top: 1px solid #fff;
    max-width: 375px;
    padding-top: 15px;
  }

  .vmv-title{
    color: #fff;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .vision-block-only ul{
    color: #fff;
    border-top: 1px solid #fff;
    padding-top: 15px;
  }
  @media (min-width: 992px) and (max-width: 1399px) {
    /* .vision-block{
      left: calc(50% + 150px);
      top: calc(-30% + -450px);
    } */
    .vision-block h3:before{
      right: 95%;
      top: calc(58px * 1.2 + 14px);
    }
    .value-block{
      left: calc(50% + 230px);
      top: calc(-30% + -300px);
    }
    .value-block h3:before{
      right: 94%;
      top: calc(58px * 1.2 + 14px);
      transform: rotate(-45deg);
    }
  }

  @media (min-width: 1200px) and (max-width: 1399px)  {
    .mission-block{
      left: calc(0% + 0px);
      top: calc(30% + -380px);
      width: 300px;
    }
    .mission-block h3::after{
      width: 170px;
      right: -48%;
      top: calc(55px * 1.2 + 43px);
      transform: rotate(45deg);
    }
  }
  @media (min-width: 992px) and (max-width: 1199px)  {
    .mission-block{
      left: calc(0% + 0px);
      top: calc(30% + -400px);
      width: 250px;
    }
    .mission-block h3::after{
      width: 170px;
      right: -57%;
      top: calc(55px * 1.2 + 40px);
      transform: rotate(45deg);
    }
  }
@media (min-width: 300px) and (max-width: 991px) {
  .mobile-view{
    display: contents;
  }
  .desktop-view{
    display: none;
  }
  
}